import * as React from "react"
import { StaticImage } from "gatsby-plugin-image"
import Layout from "../components/layout"
import SEO from "../components/seo"

const ServiceDetailsPage = () => (
    <Layout>
        <SEO title="Contacto" />
        <div className="inner-banner">
            <div className="container">
                <div className="inner-title text-center">
                    <h3>Content Marketing</h3>
                    <ul>
                        <li>
                            <a href="index.html">Home</a>
                        </li>
                        <li>
                            <i className="bx bx-chevron-right"></i>
                        </li>
                        <li>Service Details</li>
                    </ul>
                </div>
            </div>
            <div className="inner-banner-shape">
                <div className="shape-one">
                    <StaticImage src="../images/inner-banner/banner-shape1.png" alt="Images"/>
                </div>
                <div className="shape-two">
                    <StaticImage src="../images/inner-banner/banner-shape2.png" alt="Images"/>
                </div>
                <div className="shape-three">
                    <StaticImage src="../images/inner-banner/banner-shape3.png" alt="Images"/>
                </div>
                <div className="inner-banner-dots-2">
                    <StaticImage src="../images/shape/dots-shape.png" alt="Images"/>
                </div>
            </div>
        </div>

        <div className="service-details-area pt-50 pb-70">
            <div className="container">
                <div className="row">
                    <div className="col-lg-8">
                        <div className="service-left">
                            <div className="service-content">
                                <StaticImage src="../images/service/service-details.jpg" alt="Images"/>
                                <h2>Content Marketing</h2>
                                <p>
                                Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor
                                incididunt ut labore
                                et dolore plicabo. Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut quia
                                consequuntur magni
                                dolores eos qui ratione voluptatem sequi nesciunt.
                                </p>
                            </div>
                            <div className="row">
                                <div className="col-lg-6 col-sm-6">
                                    <div className="service-content-card">
                                        <h3>Real-time Keyword Data</h3>
                                        <p>Lorem ipsum dolor sit ametaut odiut perspiciatis unde omnis iste quuntur alquam
                                        quaerat rsit amet</p>
                                    </div>
                                </div>
                                <div className="col-lg-6 col-sm-6">
                                    <div className="service-content-card">
                                        <h3>Scalable Keyword Growth</h3>
                                        <p>Lorem ipsum dolor sit ametaut odiut perspiciatis unde omnis iste quuntur alquam
                                        quaerat rsit amet</p>
                                    </div>
                                </div>
                                <div className="col-lg-6 col-sm-6">
                                    <div className="service-content-card">
                                        <h3>ROI Obsessed Keywords</h3>
                                        <p>Lorem ipsum dolor sit ametaut odiut perspiciatis unde omnis iste quuntur alquam
                                        quaerat rsit amet</p>
                                    </div>
                                </div>
                                <div className="col-lg-6 col-sm-6">
                                    <div className="service-content-card">
                                        <h3>Total Keyword Growth Visibility</h3>
                                        <p>Lorem ipsum dolor sit ametaut odiut perspiciatis unde omnis iste quuntur alquam
                                        quaerat rsit amet</p>
                                    </div>
                                </div>
                            </div>
                            <div className="content-widget-area">
                                <div className="row">
                                    <div className="col-lg-7">
                                        <div className="content-widget-img">
                                            <StaticImage src="../images/service/service-details-img2.png" alt="Images"/>
                                        </div>
                                    </div>
                                    <div className="col-lg-5">
                                        <div className="content-widget-text">
                                            <h2>The Entire Solution For Content Marketer</h2>
                                            <p>Lorem ipsum dolor sit ametaut odiut perspiciatis unde omnis iste quuntur
                                            alquam quaerat rsit amet</p>
                                            <ul>
                                                <li>
                                                    <i className="bx bx-check"></i>
                                                The Field of Data Science
                                                </li>
                                                <li>
                                                    <i className="bx bx-check"></i>
                                                SEO is Uniquely Built Around
                                                </li>
                                                <li>
                                                    <i className="bx bx-check"></i>
                                                Google’s search algorithm
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <p>
                            Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt
                            ut labore tus
                            error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae
                            ab illo inventore
                            veritatis onsectetur, adipisci velit, sed quia non numquam eius modi tempora incidunt ut
                            labore et dolore
                            magnam aliquam quaerat voluptatem.
                            </p>
                            <div className="service-widget-list">
                                <ul>
                                    <li className="active">
                                        <span>75%</span>
                                        <div className="content list1">
                                            <h3>SEO & Marketing</h3>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="content list2">
                                            <h3>Keywords Results</h3>
                                        </div>
                                        <span>66%</span>
                                    </li>
                                    <li>
                                        <span>43%</span>
                                        <div className="content list3">
                                            <h3>Google Analytics</h3>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="content list2">
                                            <h3>Off Page SEO</h3>
                                        </div>
                                        <span>15%</span>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-4">
                        <div className="service-widget-right">
                            <div className="widget-category">
                                <h3>Other Services</h3>
                                <ul>
                                    <li><a href="#">AI & ML Development</a></li>
                                    <li><a href="#">Data Visualization</a></li>
                                    <li><a href="#">Data Science</a></li>
                                    <li><a href="#">Content Marketing</a></li>
                                    <li><a href="#">SEO & Internet</a></li>
                                </ul>
                            </div>
                            <div className="contact-widget">
                                <h2>Contact Info</h2>
                                <ul>
                                    <li>
                                        <i className="flaticon-telephone"></i>
                                        <div className="content">
                                            <h3>Phone: </h3>
                                            <span><a href="tel:+564325677896">+56432 567 7896</a></span>
                                        </div>
                                    </li>
                                    <li>
                                        <i className="flaticon-email-1"></i>
                                        <div className="content">
                                            <h3>Email:</h3>
                                            <span><a
                                                href="https://templates.hibootstrap.com/cdn-cgi/l/email-protection#f098959c9c9fb08a999e9b91de939f9d"><span
                                                    className="__cf_email__"
                                                    data-cfemail="e68e838a8a89a69c8f888d87c885898b">[email&#160;protected]</span></a></span>
                                        </div>
                                    </li>
                                    <li>
                                        <i className="flaticon-planet-earth"></i>
                                        <div className="content">
                                            <h3>Location:</h3>
                                            <span>112/7 New York, USA</span>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                            <div className="service-widget-two">
                                <h2>Brochures</h2>
                                <ul>
                                    <li>
                                        <i className="flaticon-pdf-file"></i>
                                        <div className="content">
                                            <h3>01:</h3>
                                            <span><a href="#">PDF Download</a></span>
                                        </div>
                                    </li>
                                    <li>
                                        <i className="flaticon-pdf-file"></i>
                                        <div className="content">
                                            <h3>02:</h3>
                                            <span><a href="#">Services Details.txt</a></span>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div className="brand-logo-area  pt-100">
            <div className="container-fluid">
                <div className="container-max">
                    <div className="brand-logo-slider owl-carousel owl-theme">
                        <div className="brand-logo-item">
                            <StaticImage src="../images/brand/brand-logo1.png" alt="Images"/>
                        </div>
                        <div className="brand-logo-item">
                            <StaticImage src="../images/brand/brand-logo2.png" alt="Images"/>
                        </div>
                        <div className="brand-logo-item">
                            <StaticImage src="../images/brand/brand-logo3.png" alt="Images"/>
                        </div>
                        <div className="brand-logo-item">
                            <StaticImage src="../images/brand/brand-logo4.png" alt="Images"/>
                        </div>
                        <div className="brand-logo-item">
                            <StaticImage src="../images/brand/brand-logo5.png" alt="Images"/>
                        </div>
                        <div className="brand-logo-item">
                            <StaticImage src="../images/brand/brand-logo6.png" alt="Images"/>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    </Layout>
)

export default ServiceDetailsPage
